@use 'sass:math';
// CSS Shiv - less broken sites in IE
main, article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
    display: block;
}

audio, canvas, video {
    display: inline-block;
}

$print-hrefs: false;
$medium-border-thickness: 2px;
$vertical-space: 2.5vh;

// Global styles
@include foundation-global-styles;
@include foundation-forms;
@include foundation-typography;
@include foundation-xy-grid-classes;

// Generic components
@include foundation-button;
//@include foundation-button-group;
@include foundation-close-button;
//@include foundation-label;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-switch;
@include foundation-table;
// Basic components
//@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-callout;
@include foundation-card;
//@include foundation-dropdown;
//@include foundation-pagination;
//@include foundation-tooltip;

// Containers
//@include foundation-accordion;
//@include foundation-media-object;
//@include foundation-orbit;
@include foundation-responsive-embed;
@include foundation-tabs;
@include foundation-thumbnail;
// Menu-based containers
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-accordion-menu;
//@include foundation-drilldown-menu;
//@include foundation-dropdown-menu;

// Layout components
//@include foundation-off-canvas;
//@include foundation-reveal;
//@include foundation-sticky;
//@include foundation-title-bar;
//@include foundation-top-bar;

// Helpers
@include foundation-float-classes;
@include foundation-flex-classes;
@include foundation-visibility-classes;
//@include foundation-prototype-classes;


@function em-calc($values) {
    $remValues: rem-calc($values);

    $max: length($remValues);

    @if $max == 1 {
        @return strip-unit(nth($remValues, 1)) * 1em;
    }

    $emValues: ();
    @for $i from 1 through $max {
        $emValues: append($emValues, strip-unit(nth($remValues, $i)) * 1em);
    }
    @return $emValues;
}

@mixin spacing() {
    @each $size in $breakpoint-classes {
        $spacing: map-get($grid-padding-gutters, $size);
        $lower-bound-size: map-get($breakpoints, $size);
        $lower-bound: -zf-bp-to-em($lower-bound-size);
        @media screen and (min-width: $lower-bound) {
            @content($spacing);
        }
    }
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    hyphens: none; /* browsers rarely hyphenate properly */
}

.body-wrapper {
    position: relative;
    overflow: hidden;
}

::-moz-selection {
    background: #94bef1;
    text-shadow: none;
    color: rgba(0, 0, 0, .66);
}

::selection {
    background: #94bef1;
    text-shadow: none;
    color: rgba(0, 0, 0, .66);
}


$accessibility-highlight-colour: $secondary-color;

@mixin accessibility-highlight() {
    outline-width: 2px !important;
    outline-style: dashed !important;
    outline-color: #000;
    box-shadow: 0 0 2px $accessibility-highlight-colour, 0 0 6px $accessibility-highlight-colour, 0 0 10px $accessibility-highlight-colour, 0 0 15px $accessibility-highlight-colour !important;
    z-index: 1;
}

html[data-whatintent=keyboard] {
    a[href],
    area[href],
    input:not([disabled]),
    select:not([disabled]),
    textarea:not([disabled]),
    button:not([disabled]),
    iframe,
    [tabindex],
    [contentEditable=true] {
        transition: none;

        &:not([tabindex='-1']) {
            &:focus {
                @include accessibility-highlight;
            }
        }

        // Uncomment to preview all links
        //@include accessibility-highlight;
    }
}


$placeholders: (
    '::-webkit-input-placeholder',
    '::-moz-placeholder',
    ':-ms-input-placeholder',
    ':-moz-placeholder',
    '::placeholder'
);

input, textarea {
    @each $placeholder in $placeholders {
        &#{$placeholder} {
            color: $black;
            opacity: .50;
        }
    }

    &:focus {
        @each $placeholder in $placeholders {
            &#{$placeholder} {
                opacity: .75;
            }
        }
    }
}

select {
    margin-bottom: 0;
    width: auto;
}

textarea {
    resize: vertical;
}

button, input, optgroup, select, textarea {
    font-family: inherit;
}

.browserupgrade {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
}

.clearfix {
    &:before {
        content: " ";
        display: table;
    }

    &:after {
        content: " ";
        display: table;
        clear: both;
    }
}

@media print {
    header, footer, aside, embed, object, iframe,
    .banner, #admin_bar_primary, .block-video, .type-slideshow {
        display: none !important;
    }
}

a, button, .button {
    transition: all 200ms linear;

    [data-whatintent="touch"] &,
    [data-whatintent="keyboard"] & {
        transition: none;
    }
}

h1, h2, h3, h4, h5, h6 {

    margin-bottom: $header-margin-bottom;
    margin-top: $header-margin-top;
    color: #000;

    a & {
        transition: all 200ms linear;
    }

    a {
        color: inherit;
        //display: block;
        &:hover {
            color: $anchor-color-hover;
        }
    }
}

@each $size, $headers in $header-styles {
    @include breakpoint($size) {
        @each $header, $header-defs in $headers {
            #{$header}, .#{$header} {
                @if map-has-key($header-defs, font-size) {
                    $font-size-temp: em-calc(map-get($header-defs, font-size));
                    font-size: $font-size-temp;
                } @else if map-has-key($header-defs, fs) {
                    $font-size-temp: em-calc(map-get($header-defs, fs));
                    font-size: $font-size-temp;
                } @else if $size == $-zf-zero-breakpoint {
                    font-size: $font-size-temp;
                }
            }
        }
    }
}

dl {

    dt {
        font-size: 1.1em;
        margin-bottom: .25em;
        margin-top: .66em;
        line-height: 1.25;
    }

    dd {
        padding-bottom: 1em;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@include breakpoint(large) {
    ul, ol, dl, p {
        font-size: em-calc(17);
    }
    p.lead {
        font-size: em-calc(20);
    }
}

.callout {
    p {
        font-size: 1rem;
        line-height: 1.4;
        color: rgba(0, 0, 0, .65);
        margin-bottom: .66em;
    }

    &.small p {
        font-size: .9rem;
        line-height: 1.3;
        margin-bottom: .4em;
    }

    &.large p {
        font-size: 1.16rem;
        line-height: 1.5;
        margin-bottom: 1em;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

hr {
    clear: none;
    border-bottom-width: $medium-border-thickness;

    &.primary {
        border-bottom-color: $primary-color;
    }

    &.secondary {
        border-bottom-color: $secondary-color;
    }

    &.success {
        border-bottom-color: $success-color;
    }

    &.warning {
        border-bottom-color: $warning-color;
    }

    &.alert {
        border-bottom-color: $alert-color;
    }
}

/* AM Forms defaults */
form.form {

    em,
    .form__note {
        font-style: normal;
        font-size: .8rem;
        opacity: .7;
        line-height: 1.1;
        display: block;
    }

    .form__note {
        margin: 1em 0;
    }

    input[type=checkbox],
    input[type=radio] {
        margin-bottom: 0;

        & + label {
            display: inline;
            color: inherit;
            font-size: 15px;
            line-height: 1.1;
        }
    }

    .type-radiobuttons,
    .type-checkboxes {
        div div {
            margin-bottom: 10px;
        }
    }

}

table.responsive {

    @media (max-width: 1024px) {

        width: 100%;
        margin: 0;
        padding: 0;
        border-collapse: collapse;
        border-spacing: 0;
        border: 0;

        thead {
            display: none;
        }

        tr {
            display: block;
            border-bottom: 2px solid #ddd !important;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none !important;
            }
        }

        td {
            display: block;
            text-align: right;
            border-bottom: 1px solid #ddd;

            &:last-child {
                border-bottom: 0;
            }

            &:before {
                content: attr(data-rt-label);
                float: left;
                font-weight: bold;
            }
        }
    }
}

$thin-spacing: 8px;
.thin-grid {
    &.grid-padding-x {
        margin-right: math.div(-$thin-spacing, 2);
        margin-left: math.div(-$thin-spacing, 2);

        > .cell {
            padding-left: math.div($thin-spacing, 2);
            padding-right: math.div($thin-spacing, 2);
        }
    }

    &.grid-padding-y {
        margin-top: math.div(-$thin-spacing, 2);
        margin-bottom: math.div(-$thin-spacing, 2);

        > .cell {
            padding-top: math.div($thin-spacing, 2);
            padding-bottom: math.div($thin-spacing, 2);
        }
    }

    $sides: (
        'bottom',
        'top',
        'left',
        'right'
    );
    $types: (
        'padding',
        'margin'
    );
    @each $type in $types {
        &.#{$type} {
            #{$type}: math.div($thin-spacing, 2) !important;
        }
        &.#{$type}-0 {
            #{$type}: 0 !important;
        }
        &.#{$type}-x {
            #{$type}-left: math.div($thin-spacing, 2) !important;
            #{$type}-right: math.div($thin-spacing, 2) !important;
        }
        &.#{$type}-y {
            #{$type}-top: math.div($thin-spacing, 2) !important;
            #{$type}-bottom: math.div($thin-spacing, 2) !important;
        }
        &.#{$type}-x-0 {
            #{$type}-left: 0 !important;
            #{$type}-right: 0 !important;
        }
        &.#{$type}-y-0 {
            #{$type}-top: 0 !important;
            #{$type}-bottom: 0 !important;
        }
        @each $side in $sides {
            &.#{$type}-#{$side} {
                #{$type}-#{$side}: math.div($thin-spacing, 2) !important;
            }
            &.#{$type}-#{$side}-0 {
                #{$type}-#{$side}: 0 !important;
            }
        }
    }
}

.svg-icon {
    display: inline-block;
    vertical-align: text-top;

    svg {
        fill: currentColor;
        height: 1.1em;
        width: 1.1em;
        display: block;
    }
}


.pagination {

    border-top: 1px solid rgba(#000, .1);
    border-bottom: 1px solid rgba(#000, .1);
    font-weight: bold;
    padding-bottom: 15px;
    padding-top: 15px;

    .pagination-previous {
        .disabled::before,
        a::before {
            margin-right: 0;
        }
    }

    .pagination-next {
        a::after,
        &.disabled::after {
            margin-left: 0;
        }
    }

    .disabled, .current, a, button {
        text-align: center;
        min-width: 40px;
        border: 2px solid #fff;
    }

    .current {
        border-color: $primary-color;
    }

    a {
        &:hover {
            color: $primary-color;
            border-color: $primary-color;
        }
    }
}

@include spacing using($spacing) {
    $sides: (
        'bottom',
        'top',
        'left',
        'right'
    );
    $types: (
        'padding',
        'margin'
    );
    @each $type in $types {
        .#{$type} {
            #{$type}: math.div($spacing, 2) !important;
        }
        .#{$type}-0 {
            #{$type}: 0 !important;
        }
        .#{$type}-x {
            #{$type}-left: math.div($spacing, 2) !important;
            #{$type}-right: math.div($spacing, 2) !important;
        }
        .#{$type}-y {
            #{$type}-top: math.div($spacing, 2) !important;
            #{$type}-bottom: math.div($spacing, 2) !important;
        }
        .#{$type}-x-0 {
            #{$type}-left: 0 !important;
            #{$type}-right: 0 !important;
        }
        .#{$type}-y-0 {
            #{$type}-top: 0 !important;
            #{$type}-bottom: 0 !important;
        }
        @each $side in $sides {
            .#{$type}-#{$side} {
                #{$type}-#{$side}: math.div($spacing, 2) !important;
            }
            .#{$type}-#{$side}-0 {
                #{$type}-#{$side}: 0 !important;
            }
        }
    }
}


@keyframes fa-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}

.icon {
    &.spin {
        animation: fa-spin 1s infinite;

        &.stepped {
            animation: fa-spin 1s infinite steps(8);
        }
    }
}

.v-align-center,
.v-align-bottom {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    > * {
        flex: 1 0 auto;
    }
}

.v-align-center {
    align-content: center;
}

.v-align-bottom {
    align-content: flex-end;
}

//More reasonable callout colour. Saturation too out of wack otherwise.
.callout {
    a {
        white-space: nowrap;
    }

    $callout-background: #f6f6f6;
    background-color: $callout-background;
    color: color-pick-contrast($callout-background, ($callout-font-color, $callout-font-color-alt));
    border: 1px solid rgba(#000, 0.2);
    @each $name, $color in $foundation-palette {
        &.#{$name} {
            $callout-background: scale-color($color, $lightness: 80%, $saturation: -40%);
            background-color: $callout-background;
            color: color-pick-contrast($callout-background, ($callout-font-color, $callout-font-color-alt));
        }
    }
}

.button {
    font-family: $header-font-family;
    font-weight: 400;
    border-radius: 100px;

    &.inversed {
        color: $tertiary-color;
        background: #fff;

        &:hover {
            color: #fff;
            background: $secondary-color;
        }
    }
}

.grid-padding-y,
.grid-padding-y-xl {
    .grid-padding-y-xl {
        margin-top: -$vertical-space;
        margin-bottom: -$vertical-space;
    }
}

.grid-padding-y-xl {
    .grid-padding-y {
        @include spacing using($spacing) {
            margin-top: math.div(-$spacing, 2);
            margin-bottom: math.div(-$spacing, 2);
        }
    }
}

.grid-padding-y-xl {
    > .cell {
        padding-top: $vertical-space;
        padding-bottom: $vertical-space;
    }
}

.global-alert {

    margin: 0;
    border-radius: 0;
    border: 0;
    z-index: 1040;

    $callout-background: #fff;
    background-color: $callout-background;
    color: color-pick-contrast($callout-background, ($callout-font-color, $callout-font-color-alt));
    @each $name, $color in $foundation-palette {
        &.#{$name} {
            $callout-background: $color;
            background-color: $callout-background;
            color: color-pick-contrast($callout-background, ($callout-font-color, $callout-font-color-alt));

            .button {
                border: 1px solid color-pick-contrast($color, (#fff, rgba(#000, .8)));
                background: color-pick-contrast($color, (#fff, rgba(#000, .8)));
                color: $color;

                &:hover {
                    background: $color;
                    color: color-pick-contrast($color, (#fff, rgba(#000, .8)));
                }
            }
        }
    }

    &.inverted {
        background-color: $black;
        color: color-pick-contrast($black, ($callout-font-color, $callout-font-color-alt));

        .button {
            border: 1px solid color-pick-contrast($black, (#fff, rgba(#000, .8)));
            background: color-pick-contrast($black, (#fff, rgba(#000, .8)));
            color: $black;

            &:hover {
                background: $black;
                color: color-pick-contrast($black, (#fff, rgba(#000, .8)));
            }
        }
    }

    p, ul, ol {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .button {
        margin: -0.35em 0;
    }

    .close-button {
        color: inherit;
        top: -4px;
        right: 0;
        display: none;
    }

    &.hide:not(.dismissable) {
        display: block;
    }

    &.dismissable {
        .close-button {
            display: block;
        }
    }

    &.footerBanner {
        box-shadow: 0 15px 20px 20px rgba(#000, .2);
        opacity: .9;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
    }
}

.tabs {
    padding-left: .5em;
    border-width: 0 0 $medium-border-thickness;
    margin-bottom: 1em;
}

.button.secondary {
    color: #fff
}
