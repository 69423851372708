.block-fileList ol {
    margin: 0;
    list-style: none;
    border-top: 1px solid rgba(#000, .1);

    a {
        display: block;
        padding: 8px 15px;

        &:hover {
            background: rgba(#000, .03);
        }
    }

    li {
        overflow: hidden;
        border-bottom: 1px solid rgba(#000, .1);
    }

    span.meta {
        float: right;
    }
}
