@use 'sass:math';

$faqBorderStyle: $medium-border-thickness solid rgba(#000, .15);

.block-faq {

    dl {
        border: $faqBorderStyle;
        border-top-width: 0;
        margin-bottom: 0;

        dt, dd {
            margin: 0;
        }

        dt a, dd {
            display: block;
            @include spacing using($spacing) {
                padding-left: math.div($spacing, 1.75);
                padding-right: (math.div($spacing, 1.75)) + 25px;
                padding-top: math.div($spacing, 2.5);
                padding-bottom: math.div($spacing, 3);
            }
        }

        dt {
            border-top: $faqBorderStyle;
            > a {
                position: relative;
                color: $black;

                &:after {
                    background: url('../static/icons/solid/plus.svg') no-repeat center center;
                    width: 20px;
                    content: '';
                    font-weight: normal;
                    position: absolute;
                    display: block;
                    right: 15px;
                    top: 0;
                    bottom: 0;
                }

                &[aria-expanded=false] {
                    background: rgba(#000, .025);

                    &:after {
                        background-image: url('../static/icons/solid/minus.svg');
                    }
                }
            }
        }
    }
}
