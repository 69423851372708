.block-bannerImage {
    position: relative;

    .banner-content {
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: flex;
        min-width: 200px;
        align-items: center;
        pointer-events: none;

        .banner-text {
            font-weight: bold;
            color: #FFFFFF;
            @media (max-width: 1020px){
                width: 600px;
                @media (max-width: 768px){
                    width: 300px;
                }
            }
            }

        .button-container {
            &:not(:first-child) {
                margin-top: $vertical-space;
            }
            a {
                white-space: nowrap;
            }
        }

        .cell {
            pointer-events: auto;
        }

        &.coloured-block {
            .grid-container {
                position: relative;
            }

            background-color: transparent !important;

            &:before {
                opacity: 0.75;
            }

            &.colour-clear:before {
                display: none;
            }
        }

    }

    .b-container {

        overflow: hidden;
        position: relative;
        background: center center no-repeat;
        background-size: cover;

        height: 40vh;
        max-height: 600px;
        min-height: 250px;

        &.size-small {
            height: 30vh;
            max-height: 500px;
            min-height: 250px;
        }

        margin-left: -2000px;
        margin-right: -2000px;

        @include breakpoint(medium) {
            &.size-large {
                height: 50vh;
                max-height: 900px;
                min-height: 300px;
            }
        }

        .banner-wrapper {
            max-width: 100vw;
            height: 100%;
            margin: auto;
            position: relative;

            img {
                position: absolute;
                width: 100%;
                top: 0;
                @include breakpoint(small only) {
                    top: 0 !important;
                }
            }

            .banner {
                height: 100%;
                max-width: none;
                min-height: 0;
                max-height: none;
            }
        }


    }
}
