$nav-link-padding: .6em;

.quicklinks-content {

    p > * {
        vertical-align: middle;
    }

    a {
        margin-right: 0 !important;
        margin-left: 10px;
    }
}

header {
    position: relative;
    z-index: 1;
    background: $white;
    border-bottom: 5px solid $primary-color;

    .logo {
        margin: 0 auto;
        display: block;
        float: left;
        text-align: center;
        width: 150px;

        @include breakpoint(medium) {
            width: 200px;
        }

        @include breakpoint(large) {
            width: 250px;
        }

        img {
            display: block;
            width: 100%;
        }

        &:hover {
            opacity: 0.666;
        }
    }

    .quicklinks {
        float: right;
        text-align: right;
    }

    .menu-icon a {
        z-index:9;
        overflow: hidden;
        position: absolute;
        right: 0;
        bottom: 0;
        white-space: nowrap;
        padding: 8px 15px;
        display: none;
        font-size: 1.25rem;
        font-family: $header-font-family;

        @include breakpoint(medium down) {
            display: block;
        }

        &.open {
            background: $secondary-light-color;
            color: $primary-color;
            .menu-box{
                position: fixed;
                top: 3%;
                right: 3%;
            }

            span {
                transform: rotate(45deg);
                background: $primary-color;

                &:after {
                    opacity: 0;
                }

                &:before {
                    transform: rotate(90deg);
                    top: 0;
                }
            }
        }

        span {
            display: inline-block;
            background: $primary-color;
            height: 3px;
            width: 26px;
            position: relative;
            margin-left: 10px;
            vertical-align: middle;
            transition: linear 50ms all;

            &:before,
            &:after {
                transition: linear 50ms all;
                position: absolute;
                display: block;
                background: inherit;
                height: 3px;
                width: inherit;
                content: "";
                left: 0;
                top: -10px;
            }

            &:after {
                top: 10px;
            }
        }
    }
}

.nav-container {

    position: relative;
    z-index: 1;

    nav {

        display: none;
        clear: both;
        font-family: $header-font-family;

        .quicklinks-content {
            padding: 0 .6em 1em;
            font-size: .9em;
        }

        a {
            margin-top: 10px;
        }

        @include breakpoint(medium down) {
            padding: 8px 0 9px;
            position: absolute;
            left: 0;
            background: $secondary-light-color;
            right: 0;
            top: 100%;
            z-index: 1;
            font-size: rem-calc(19);

            &.open {
                display: block;
            }
        }

        @include breakpoint(large) {
            padding-top: 26px;
            display: block;
            clear: right;
            float: right;
        }

        ul {
            color: $primary-color;
            margin: 0;
            padding: 0;
            list-style: none;
            line-height: 40px;

            @include breakpoint(large) {
                clear: none;
                line-height: 2.7em;
                font-size: rem-calc(20);
                margin-left: -$nav-link-padding;
            }

            //@include breakpoint(xlarge) {
            //    font-size: rem-calc(24);
            //}

            a {
                text-decoration: none;
                display: block;
                padding: 0 $nav-link-padding;
            }

            a:hover,
            li:hover > a,
            li.active > a {
                color: $secondary-color;
            }

            li {

                &.open > ul {
                    display: block;
                }

                @include breakpoint(large) {
                    display: inline-block;
                    &:hover > ul {
                        display: block;
                    }
                }

                &.active > a {
                    cursor: default;
                }

                @include breakpoint(large) {
                    &.level-1.has-children > a:after {
                        @include css-triangle(5px, currentColor, 'down');
                        display: inline-block;
                        margin-left: 5px;
                        vertical-align: middle;
                        margin-top: -1px;
                    }
                }

                @include breakpoint(medium down) {
                    &.has-children > a:after {
                        @include css-triangle(5px, currentColor, 'down');
                        display: inline-block;
                        margin-left: 2px;
                        vertical-align: middle;
                        margin-top: -2px;
                    }
                }
            }

            ul {
                font-weight: normal;
                padding-left: 20px;
                margin: 0;
                line-height: 32px;
                margin-top: -5px;
                margin-bottom: 7px;
                display: none;
                text-align: left;

                @include breakpoint(large) {
                    display: none;
                    position: absolute;
                    background: white;
                    border-top: $medium-border-thickness solid $primary-color;
                    padding: 5px 0;
                    margin-bottom: 0;
                    margin-top: -$medium-border-thickness;
                    //margin-left: -$nav-link-padding*.666;
                    line-height: 40px;
                    box-shadow: 0 5px 40px rgba(#000, .15),  0 1px 2px rgba(#000, .075);
                    min-width: 150px;

                    a {
                        color: inherit;
                    }

                    ul {
                        display: none !important;
                    }
                }

                li {
                    display: block;
                    float: none;
                }

                a {
                    font-size: .9em;
                    //padding: 0 $nav-link-padding*1.333;
                }
            }
        }
    }
}
