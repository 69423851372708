@use 'sass:math';

.block-sectionHeader {

    .inner {
        padding: math.div($vertical-space, 2) 0;
        position: relative;
    }

    h2 {
        margin: 0;

        > span {
            display: inline-block;
            padding-bottom: 3px;
            border-bottom: solid currentColor $medium-border-thickness;
        }
    }

    .button-container {
        margin-top: $vertical-space * .333;
    }
}
