@use 'sass:math';

.block-featureList {

    .feature-list {
        margin: 0;
        padding: 0;
        list-style: none;
        display: table;

        p {
            line-height: 1.33;
            margin-bottom: 0;
        }

        li {
            position: relative;
            display: flex;
            margin-bottom: math.div($vertical-space, 2);

            &:last-child {
                margin-bottom: 0;
            }

        }

        .content-wrapper, .marker-wrapper {
            display: flex;
        }

        .marker-wrapper {
            padding-right: 20px;
            transform: translateY(-5px);
        }

        .marker {
            border-radius: $global-radius;
            font-weight: bold;
            width: 1.85em;
            height: 1.85em;
            padding: 0;
            line-height: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            background: $primary-color;
            color: $white;

            &.style-outlined {
                background: transparent !important;
                padding: .05em;
                color: $primary-color;
                border: .15em solid $primary-color;
            }

            & > span {
                display: inline-flex;
            }
        }
    }

    &.coloured-block .inner {
        padding: $vertical-space 0;
    }

    @each $palette-name, $palette in $palettes {
        &.colour-#{$palette-name} {
            .feature-list .marker {
                background: map_get($palette, highlight);
                color: map_get($palette, background);

                &.style-outlined {
                    color: map_get($palette, highlight);
                    border: .15em solid map_get($palette, highlight);
                }
            }
        }
    }

}
