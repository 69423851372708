.block-videoGallery {

    .vid-thumb {

        background-position: 50% 50%;
        background-size: cover;
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
        position: relative;

        &:before{
            width: 100%;
            background: rgba(#000, .15);
            content: '';
            display: block;
            padding-top: 66.66%;
        }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 54px;
            height: 54px;
            transform: translate(-27px, -27px);
            border-radius: 100px;
            background: rgba(#000, .333);
            display: block;
            font-size: 1.75em;
            color: #fff;
            filter: drop-shadow(0 0 40px rgba(#000, .5));
        }

        &:hover {
            &:before {
                background: rgba(#000, .3);
            }
        }

        img {
            transition: linear 150ms all;
            opacity: 0.6;
            width: 60px;
        }

        &:hover {
            opacity: 1;

            img {
                opacity: 1;
            }
        }

    }

    .grid-x {
        @include spacing using($spacing) {
            margin-bottom: -24px;
        }
    }

    &.coloured-block .inner {
        padding: $vertical-space 0;
    }
}
