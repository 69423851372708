.banner-wrapper {

    position: relative;
    overflow: hidden;
    z-index: 0;


    .slider {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .video-banner-wrapper {
        max-width: 1920px;
        max-height: 800px;
        margin-left: auto;
        margin-right: auto;
        background-color: rgba(0,0,0,0.4);
        z-index: 3;
    }

    .video-banner {
        display: block;
        z-index: -1;
    }
}

.banner {
    position: relative;
    //max-width: 1680px;
    margin: 0 auto;
    overflow: hidden;
    min-height: 40vh;
    max-height: 600px;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0,0,0,0.5);
    }

    &, .slide {
        background: center center no-repeat;
        background-size: cover;
    }

    p {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 8px 14px 9px;
        margin: 0;
        color: #FFFFFF;
        max-width: 100%;
        border-radius: 0 $global-radius 0 0;
        font-weight: normal;
        font-size: rem-calc(14);
        line-height: 1.25;
    }

    .slide,
    .slick-slide,
    .slick-slide > div,
    .slick-list,
    .slick-track {
        height: 100%;
    }
}

.banner-overlay {

    height: calc(100% - 60px);
    width: 100%;
    position: absolute;
    z-index: 1;
    font-weight: 500;
    color: #FFFFFF;

    p {
        font-size: 1.2em;
        z-index: 7;
        @media (max-width: 1140px){
            font-size: 0px;
        }
    }
    .grid-container, .grid-x {
        height: 100%;
    }

    h1 {
        font-size: 3em;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 0;
        z-index: 7;
        @media (max-width: 1140px){
            font-size: 2em;
            @media (max-width: 480px){
                font-size: 1em;
            }
        }
    }

    h3 {
        font-size: 1.2em;
        color: #FFFFFF;

        z-index: 7;
        @media (max-width: 1040px){
            font-size: 1em;
            @media (max-width: 900px){
                font-size: 0.8em;
                @media (max-width: 370px){
                    font-size: 0.6em;
                }
            }
        }
    }

    p:last-child {
        margin-bottom: 20px;
    }
}
