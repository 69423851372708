.block-image {
    .img {
        position: relative;
    }

    img {
        width: 100%;
        display: block;
    }

    p {
        line-height: 1.25;
        font-size: .9rem;
        color: smart-scale($black, 30%);
    }

    @include breakpoint(large) {
        & > div {
            &.size-small {
                width: 20%;
            }

            &.size-large {
                width: 45%;
            }
        }
    }
}
