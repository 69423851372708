.content-blocks {

    border-top: 1px solid transparent;
    margin-top: -1px;

    //border-top: 1px solid transparent;
    //border-bottom: 1px solid transparent;

    p:last-child {
        margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        &:first-child {
            margin-top: 0;
        }
    }

    &:after {
        content: "";
        display: table;
        clear: both;
    }

    a:not(.button) {
        font-weight: 500;
    }
    strong, b {
        a:not(.button) {
            font-weight: 700;
        }
    }

    //margin-bottom: $vertical-space;

    .block {
        margin-top: $vertical-space;

        &:last-child {
            margin-bottom: $vertical-space;
        }
    }

    .colour-primary:last-child {
        margin-bottom: -5px !important;
    }

    .block:hover a.magnify {
        visibility: visible;
        opacity: .66;
    }

    a.magnify {
        transition: opacity 200ms linear;
        color: #fff;
        font-size: 1.8rem;
        text-align: center;
        width: 40px;
        height: 43px;
        line-height: 40px;
        right: 10px;
        top: 10px;
        filter: drop-shadow(1px 1px 1px #333);
        position: absolute;
        opacity: .66;

        &:before {
            text-indent: 999px;
        }

        &:hover {
            opacity: 1;
            text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        }
    }


    [data-whatintent="mouse"] & {
        a.magnify {
            visibility: hidden;
            opacity: 0;
        }

        .block:hover a.magnify {
            visibility: visible;
            opacity: .66;
        }
    }

    p.button-container{
        margin-bottom: -.5rem !important;
        a {
            margin-bottom: .5rem !important;
        }
    }
}
