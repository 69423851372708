.block-gallery {

    .thumbnail {
        margin-bottom: 24px;
    }

    .grid-x {
        @include spacing using($spacing) {
            margin-bottom: -24px;
        }
    }

    &.coloured-block .inner {
        padding: $vertical-space 0;
    }
}
