.block-table {
    table.non-responsive {
        width: auto;
        margin: auto;

        &.fullWidth {
            width: 100%;
        }
    }

    table.responsive {

        width: auto;
        //margin: auto;

        &.fullWidth {
            width: 100%;
        }
    }
}
