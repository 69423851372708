.block-textImageCombo {

    .grid-x {
        position: relative;
    }

    .media {
        overflow: hidden;
        background: center center no-repeat;
        background-size: cover;
        padding: 0;
        position: relative;
        &:before {
            @include breakpoint(small only) {
                content: '';
                display: block;
                padding-top: 66.66%;
                position: relative;
            }
        }
    }

    @include breakpoint(medium) {

        .img-position-right {
            .text {
                order: 1;
            }
            .media {
                order: 2;
            }
        }

        .alternativeLayout {
            &.img-position-right {

                .inner {
                    margin-right: 0;
                }

                .media {
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                    clip-path: polygon(18% 0, 100% 0, 100% 100%, 0% 100%);
                }
            }

            &.img-position-left {

                .inner {
                    margin-left: 0;
                }

                .media {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                    clip-path: polygon(0 0, 100% 0, 82% 100%, 0% 100%);
                }
            }
        }
    }

    &.coloured-block.block:before {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    &.colour-default .img-position-left .inner {
        margin-right: 0;
    }

    &.colour-default .img-position-right .inner {
        margin-left: 0;
    }

    &:before {
        display: none;
    }

    &,
    .grid-x,
    .media {
        overflow: hidden;
        border-radius: $global-radius;
    }

    .media {
        border-radius: $global-radius - 1 ;
    }

    @include breakpoint(large) {
        .inner {
            margin: 0 $vertical-space*.5;
        }
    }

    @include breakpoint(xlarge) {
        .inner {
            margin: $vertical-space*.3 $vertical-space;
        }
    }

    .button-container {
        @include breakpoint(small only) {
            text-align: center !important;
        }
    }

    img {
        display: none;
    }

    @include breakpoint(medium) {
        .inner {
            min-height: 150px;
        }
    }
}
