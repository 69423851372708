.block-slideshow {
    .wrapper {
        display: block !important;
    }

    p {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 8px 14px 9px;
        background: rgba(#000, .85);
        margin: 0;
        color: #fff;
        max-width: 100%;
        border-radius: 0 $global-radius 0 0;
        font-weight: normal;
        font-size: rem-calc(14);
        line-height: 1.25;
    }

    .slick-list {
        margin: 0;
        padding: 0;
    }
}
