.block-textWithButton,
.block-text {

    ul, ol, p, table {
        &:last-child {
            margin-bottom: 0;
        }
    }

    // When list next to left floated image, line up bullets properly
    ul, ol {
        list-style-position: inside;
        margin-left: 0;
        display: table;

        li {
            margin-left: 23px;
            text-indent: -23px;
        }
    }
}

// Firefox only
@supports (-moz-appearance:meterbar) {
    .block-textWithButton,
    .block-text {
        ul, ol {
            li {
                text-indent: -15px;
            }
        }
    }
}
