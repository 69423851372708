.block-form {
    @include breakpoint(large) {
        & > div {
            &.position-left,
            &.position-right {
                width: 45%;
            }

            &.position-center {
                width: 70%;
            }
        }
    }
}
