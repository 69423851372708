/* Slick Arrows */
.slick-slider {

    .slick-slide {
        position: relative;
    }

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        z-index: 1;
        cursor: pointer;
        top: 50%;
        margin-top: -20px;
        text-align: center;
        line-height: 43px;
        height: 40px;
        width: 40px;
        padding: 0;
        border-radius: 0;
        border: none;
        font-size: 1.5rem;
        background: $primary-color;

        &:hover {
            background: $secondary-color;
        }

        &.slick-disabled {
            opacity: 0.33;
            pointer-events: none;
        }
    }

    .slick-prev {
        border-radius: 0 $global-radius $global-radius 0;
        left: 0;
    }

    .slick-next {
        border-radius: $global-radius 0 0 $global-radius;
        right: 0;
    }
}

// Slick Dots

.slick-dots {
    position: absolute;
    bottom: 6px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
        position: relative;
        display: inline-block;
        margin: 0 1px;
        padding: 0;
        cursor: pointer;
        opacity: 1;

        button {
            border: 0;
            background: transparent;
            display: block;
            line-height: 0;
            box-sizing: content-box;
            width: 13px;
            height: 13px;
            padding: 10px;
            font-size: 0;
            color: transparent;
            position: relative;
            cursor: pointer;
            opacity: .5;

            &:hover {
                opacity: 1;
            }

            &:before {
                position: absolute;
                top: 10px;
                left: 10px;
                height: 13px;
                width: 13px;
                content: '';
                font-size: 4em;
                line-height: 20px;
                text-align: center;
                border-radius: 100px;
                background-color: #000;
                box-shadow: 0 1px 30px #fff, 0 1px 1px #fff, 0 1px 60px #fff;
            }
        }

        &.slick-active button {
            pointer-events: none;
            cursor: default;
            opacity: 1;
        }
    }
}
