@use 'sass:math';

.content-blocks {

    //
    // Colouring
    //
    .coloured-block {
        position: relative;

        .inner {
            &.extra-space {
                padding: $vertical-space 0;
            }

            position: relative;
        }

        &:before {
            position: absolute;
            pointer-events: none;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: '';
            z-index: 0;

            .has-aside & {
                display: none;
            }
        }

        &.block:before {
            @include spacing using($spacing) {
                margin-left: math.div(-$spacing, 2);
                margin-right: math.div(-$spacing, 2);
            }
        }

        &.expand-left,
        &.expand-both {
            &:before {
                left: -2000px;
            }
        }

        &.expand-right,
        &.expand-both {
            &:before {
                right: -2000px;
            }
        }

    }

    .block, .coloured-block {

        &.colour-secondary {
            .button {
                color: #fff;

                &:hover {
                    color: $black;
                }
            }
        }

        @each $palette-name, $palette in $palettes {
            &.colour-#{$palette-name} {

                background-color: map_get($palette, background);
                color: color-pick-contrast(map_get($palette, background), map_get($palette, colour));

                &:before {
                    background-color: map_get($palette, background);
                }

                &.has-columns .columns {
                    column-rule-color: map_get($palette, highlight);
                }

                + .mt-0.colour-#{$palette-name}:not(.block-textImageCombo) {
                    > .inner > .grid-padding-y,
                    > .inner > .grid-padding-y-xl,
                    > .grid-padding-y,
                    > .grid-padding-y-xl {
                        &:first-child > .cell {
                            padding-top: 0;
                        }
                    }
                }

                @if $palette-name != 'default' {
                    @if $palette-name != 'neutral' {
                        @if $palette-name != 'inverted' {

                            @if $palette-name != 'secondary' {
                                a:not(.button) {
                                    color: color-pick-contrast(map_get($palette, background), ($white, $black, map_get($palette, highlight)));
                                    &:hover {
                                        color: #fff;
                                    }
                                }
                            }

                            .button {
                                text-decoration: none;
                                background-color: map_get($palette, highlight);
                                color: color-pick-contrast(map_get($palette, highlight), ($black, $white, $primary-color, $secondary-color));

                                &:hover {
                                    background-color: map_get($palette, hover);
                                    color: color-pick-contrast(map_get($palette, hover), ($black, $white, $primary-color, $secondary-color));
                                }

                                &.hollow {
                                    border-color: map_get($palette, highlight);
                                    color: map_get($palette, highlight);
                                    background: transparent;

                                    &:hover {
                                        text-decoration: none;
                                        background-color: map_get($palette, highlight);
                                        color: color-pick-contrast(map_get($palette, highlight), ($black, $white, $primary-color, $secondary-color));
                                    }
                                }
                            }
                        }

                        h1, h2, h3, h4, h5, h6 {
                            color: inherit;

                            span {
                                border-color: map_get($palette, highlight);
                            }
                        }
                    }
                }
            }
        }
    }

    //
    // Horizontal Spacing
    //
    .px-0 {
        @include spacing using($spacing) {
            margin-left: math.div(-$spacing, 2);
            margin-right: math.div(-$spacing, 2);
        }
    }

    //
    // Gallery
    //
    .gallery {
        > div {
            margin-bottom: -6px;
        }

        .thumbnail {
            margin-bottom: 6px;
            transition: all linear 150ms;
            position: relative;

            span {
                display: block;
                padding-top: 56.25%;
                background: url("../static/play-button.svg") no-repeat center center;
                width: 100%;
                opacity: .66;
                transition: opacity 150ms linear;
            }

            &:hover {
                transform: scale(1.01);

                span {
                    opacity: 1;
                }
            }
        }
    }

    //
    // Clearing
    //
    .clear {
        clear: both;
    }

    //
    // Positioning
    //
    .positioned {

        clear: both;

        &.position-left,
        &.position-right {
            margin-bottom: $vertical-space;
        }

        @include breakpoint(medium) {

            &.position-left {
                float: left;
            }
            &.position-right {
                float: right;
            }
            &.position-center {
                margin-left: auto;
                margin-right: auto;
                min-width: 320px;
            }

            &.position-full {
                width: auto;
            }

            /* Padding */
            &.position-left {
                @include spacing using($spacing) {
                    margin-right: $spacing;
                }
            }

            &.position-right {
                @include spacing using($spacing) {
                    margin-left: $spacing;
                }
            }
        }

        @include breakpoint(small only) {
            &.position-left,
            &.position-right,
            &.position-center {
                margin-left: auto;
                margin-right: auto;
                max-width: 300px;
            }
        }

        @include breakpoint(medium) {
            width: 45%;
        }

        @include breakpoint(large) {
            width: 33%;
        }

        @include breakpoint(medium) {
            //+ .block:not(.positioned) {
            //    margin-top: 0;
            //}
        }

    }

    //
    // Top Margin 0
    //
    .mt-0 + .mt-0 {
        margin-top: 0 !important;
    }

    .mt-0:first-child {
        margin-top: 0;
    }

    .mt-0:last-child {
        margin-bottom: 0;
    }

    .wider-block {
        @include breakpoint(xlarge) {
            margin-left: -140px;
            margin-right: -140px;
        }
    }

    .block:not(.coloured-block),
    .block-textImageCombo {
        margin-bottom: $vertical-space;
    }
}

//.content-blocks .block.extra-wide {
//    @include breakpoint(medium) {
//        margin-left: -9%;
//        margin-right: -9%;
//
//    }
//    @include breakpoint(large) {
//        margin-left: -25.5%;
//        margin-right: -25.5%;
//    }
//}
