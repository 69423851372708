.testimonials {
    img {
        width: 50%;
        margin-bottom: .5em;
    }
    .quote > div {
        font-size: .9em;
        p {
            margin-bottom: .5em;
        }
    }
}
