@use 'sass:math';

main {

    position: relative;
    z-index: 0;

    //@include spacing using ($spacing) {
    //        padding-bottom: $spacing;
    //}

    h2.page-title {
        @include breakpoint(large) {
            margin-top: -3px;
        }
    }

    > .grid-container {
        max-width: 1000px;
    }
}

aside {

    @include spacing using($spacing) {
        margin-bottom: $spacing;
    }

    & > .menu {

        //font-weight: 500;
        background: rgba(#000, .033);
        border-right: $medium-border-thickness solid $primary-color;

        @include spacing using($spacing) {
            margin-left: math.div(-$spacing, 2);
            margin-right: math.div(-$spacing, 2);
            li a {
                padding-left: math.div($spacing, 2);
                padding-right: math.div($spacing, 2);
            }
        }
    }
}

.social-media {
    display: inline-block;
    margin-bottom: -.33rem;

    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        line-height: 1;
        margin-right: .33rem;
        margin-bottom: .33rem;
        height: 2em;
        width: 2em;
        font-size: 1.2em;
        border-radius: 100%;
        color: #fff;
        background-color: $secondary-color;

        &.facebook {
            background-color: #3b5998 !important;
        }

        &.youtube {
            background-color: #cc181e !important;
        }

        &.tiktok {
            background-color: #69C9D0 !important;
        }

        &.instagram {
            background-color: #d62976 !important;
        }

        &.twitter {
            background-color: #1da1f2 !important;
        }

        &.linkedin {
            background-color: #0077b5 !important;
        }

        &.pinterest {
            background-color: #bd081c !important;
        }

        &.tripadvisor {
            background-color: #33e0a1 !important;
        }

        &.twitch {
            background-color: #9146ff !important;
        }

        &.tumblr {
            background-color: #34526f !important;
        }

        &.medium {
            background-color: #e65a3a !important;
        }

        // uncomment for borders
        //&:after{
        //    content: '';
        //    display: block;
        //    position: absolute;
        //    height: calc(100% - 4px);
        //    width: calc(100% - 4px);
        //    border: #fff 1px solid;
        //    border-radius: 100%;
        //}

        &:hover {
            color: #fff;
            background-color: $primary-color !important;
        }

    }
}


.logo-list {

    text-align: center;


    @include spacing using($spacing) {
        margin-top: math.div(-$spacing, 2);
        margin-bottom: math.div(-$spacing, 2);
    }

    .logo {

        vertical-align: middle;
        display: inline-block;

        @include spacing using($spacing) {
            padding: math.div($spacing, 2);
        }

        a, span {
            display: inline-block;
            vertical-align: middle;
        }

        a:hover {
            transform: scale(1.1);
        }

        img {
            transition: all 100ms linear;
            max-width: 100%;
            display: block;
        }

        p {
            padding-top: 12px;
            line-height: 1.1;
            color: #777;
            font-size: rem-calc(15);
        }
    }
}
