.block-twoColumnTextOverImage {

    .grid-x:not(.color-neutral) {
        h1, h2, h3, h4, h5, h6 {
            color: inherit;
        }
    }

    .coloured-block {

        .inner {
            margin: 0 $vertical-space;
            position: relative;
        }

        &:before {
            opacity: 0.9;
        }

    }

}
