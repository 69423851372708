.block-featureBoxes {

    text-align: center;

    &.coloured-block {
        .inner {
            padding: $vertical-space 0;
        }
    }

    .box-container {
        img {
            border-radius: 100%;
        }
    }
}
