
footer {

    .flags-align{
        display: flex;
        width: 100%;
        align-items: center;
        .column{
            float: center;
            width: 33.33%;
            padding: 5px;
            img{
                height: 75px;
                object-fit: fill;
            }
        }
    }
    .CLC-align{
        display: flex;
        .column{
            float: left;
            img{
                height: 125px;
                width: 100%;
                object-fit: cover;
            }
        }
    }

    .sponsor-align {
        .grid-container{
            display: grid;
            width: 100%;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: repeat(3, 50px);
            grid-row-gap: 10px;
            .grid-element{
                padding: 5px;
                margin-bottom: 10px;
                img{
                    height: 50px;
                    object-fit: contain;
                    display: block;
                }
            }
        }
    }

    background: $tertiary-color;
    font-size: .875rem;
    color: #fff;

    h1, h2, h3, h4 {
        color: inherit;
    }

    a {
        color: inherit;

        &:hover {
            color: $primary-color;
        }
    }

    p, ul, ol {
        font-size: inherit;
        margin-bottom: .5em;

        &:last-child {
            margin-bottom: 0;
        }
    }

    h4 {
        margin-top: 0;
        margin-bottom: 1em;
        font-size: 1.1666em;
    }

    .legal {
        border-top: $medium-border-thickness solid rgba(#fff, .1);

        .links {
            a {
                padding-right: 10px;

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}
