.block-textWithButton {
    .columns{
        width: 100%;
        word-wrap: break-word;
    }


    &.has-columns .columns {

        column-gap: 40px;
        column-rule: $medium-border-thickness solid rgba(#666, .5);

        @include breakpoint(medium) {
            column-count: 2;
        }
        @include breakpoint(large) {
            &.columns-3 {
                column-count: 3;
            }
        }
    }

    &.coloured-block .inner {
        padding: $vertical-space 0;
    }

    @include breakpoint(small only) {
        .button-container {
            margin-top: 1em;
            text-align: center;
        }
    }

    .cell {

        position: relative;
        word-wrap: break-word;

        &.shrink {
            max-width: 50%;
            @include breakpoint(medium) {
                max-width: 66%;
            }
            @include breakpoint(large) {
                max-width: 75%;
            }
        }

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
